<template>
	<div class="user_content">
		<!-- 头部 -->
		<div class="user_head">
			<div class="search">
				<el-input type="text" v-model="head_title" class="inp" placeholder="id/用户名"></el-input>
				<el-select class="inp" v-model="search_sel" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-button type="primary" icon="el-icon-search" @click="onsearch"></el-button>
			</div>
			<div class="add">
				<el-button type="primary" @click="changeinfo">添加用户</el-button>
			</div>
		</div>
		<!-- 用户列表 -->
		<el-table ref="multipleTable" :data="tableData" border height="100" style="flex: 1; overflow-y: auto"
			@selection-change="handleSelectionChange">
			<el-table-column fixed type="selection" width="55"> </el-table-column>
			<el-table-column fixed prop="id" label="id" width="80"> </el-table-column>
			<el-table-column prop="nickname" label="昵称"></el-table-column>
			<el-table-column prop="user" label="账号"></el-table-column>
			<el-table-column prop="ip" label="登录IP" width="120"> </el-table-column>
			<el-table-column label="登录时间" width="150">
				<template slot-scope="scope">
					<span v-if="scope.row.is_login === 0">无</span>
					<span v-else>{{scope.row.login_time}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" width="150"></el-table-column>
			<el-table-column prop="status" width="90" label="状态">
				<template slot-scope="{row: {status}}">
					<el-button v-if="+status === 0" size="small">申请中</el-button>
					<el-button v-else-if="+status === 1" type="primary" size="small">已启用</el-button>
					<el-button v-else type="danger" size="small">已禁用</el-button>
				</template>
			</el-table-column>
			<el-table-column fixed="right" width="240" label="操作">
				<template slot-scope="scope">
					<template v-if="scope.row.is_user!=1">
						<el-button v-if="scope.row.status!=1" @click="handleClick(scope.row,1)" type="primary"
							size="small">启用</el-button>
						<el-button v-else @click="handleClick(scope.row,0)" type="danger" size="small">禁用</el-button>
					</template>
					<el-button type="primary" size="small">编辑</el-button>
					<template v-if="scope.row.is_user!=1">
						<el-button type="primary" size="small">删除</el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>
		<!-- 添加用户 -->
		<el-dialog title="添加用户" :visible.sync="dialogFormVisible" class="dialog">
			<div class="box">
				<div class="row">
					<div class="name"><span style="color: red">*</span>昵称</div>
					<el-input class="inp" v-model="nickname" />
					<div class="tips"><span style="color: red">*</span>2~10字符</div>
				</div>
				<div class="row">
					<div class="name"><span style="color: red">*</span>账号</div>
					<el-input class="inp" v-model="user" />
					<div class="tips">
						<span style="color: red">*</span>将成为您唯一的账号
					</div>
				</div>
				<div class="row">
					<div class="name"><span style="color: red">*</span>手机号</div>
					<el-input class="inp" v-model="phone" />
					<div class="tips"></div>
				</div>
				<div class="row">
					<div class="name"><span style="color: red" v-if="!is_edit">*</span>密码</div>
					<el-input class="inp" v-model="pwd" :placeholder="is_edit?'不修改请为空':''" />
					<div class="tips">6~16个字符</div>
				</div>
				<div class="row">
					<div class="name"><span style="color: red" v-if="!is_edit">*</span>确认密码</div>
					<el-input class="inp" v-model="qrpwd" :placeholder="is_edit?'不修改请为空':''" />
					<div class="tips"></div>
				</div>
				<div class="row">
					<div class="name"><span style="color: red">*</span>状态</div>
					<div class="inp">
						<el-radio v-model="sta" :label="1">启用</el-radio>
						<el-radio v-model="sta" :label="2">禁用</el-radio>
					</div>
				</div>
				<div class="row">
					<div class="name"><span style="color: red">*</span>超级管理员</div>
					<div class="inp">
						<el-radio v-model="is_super" :label="0">否</el-radio>
						<el-radio v-model="is_super" :label="1">是</el-radio>
					</div>
				</div>
				<div class="row" v-if="is_super!=1">
					<div class="name"><span style="color: red">*</span>权限</div>
					<div class="inp">
						<el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
						<div style="margin:5px"></div>
						<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
							<el-checkbox v-for="item in cities" :label="item.id" :key="item.id">{{item.name}}
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="info_cel">取 消</el-button>
				<el-button type="primary" @click="info_sub">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 分页 -->
		<page-tab :page="page" :count="page_count" @change="change_page"></page-tab>
	</div>
</template>

<script>
	import axios from "../../common/axios";
	import pageTab from "../pages/index";
	export default {
		components: {
			pageTab
		},
		data() {
			return {
				tableData: [],
				multipleSelection: [],
				page_tab: 1, // 页码
				dialogFormVisible: false, //弹窗关闭/打开
				sta: 1,
				nickname: "",
				user: "",
				phone: "",
				pwd: "",
				qrpwd: "",
				head_title: "",
				page: 1,
				search_sel: 0,
				page_count: 1,
				is_super: 0,
				is_edit: false,
				checkAll: false, //全选
				checkedCities: [], //选中的内容
				options: [{
						value: 0,
						label: '全部'
					},
					{
						value: 1,
						label: '申请中'
					},
					{
						value: 2,
						label: '已启用'
					},
					{
						value: 3,
						label: '已禁用'
					}
				],
				cities: [{
						id: 1,
						name: '上海'
					},
					{
						id: 2,
						name: '北京'
					},
					{
						id: 3,
						name: '广州'
					},
					{
						id: 4,
						name: '深圳'
					},
				], //多选框内容
			};
		},
		mounted() {
			this.get_user();
		},
		methods: {
			onsearch() {
				this.page = 1;
				this.get_user();
			},
			change_page(e) {
				let page = e.value;
				this.page = page;
				this.get_user(page);
			},
			get_user(page = 1) {
				
				let title = this.head_title;
				let status = this.search_sel;

				
				var user_list = [];
				let url = "/adminshop/user/get_user.html";
				let data={
					title,
					status,
					page
				}
				axios.post(url,data).then((res) => {
					let data = res.data;
					if (res.success) {
						var list = data.list;
						if (list != null && list != undefined && list != "") {
							user_list = data.list;
						}
					}
					this.tableData = user_list;
					this.page_count = data.pages;
				});
			},
			changeinfo() {
				this.dialogFormVisible = !this.dialogFormVisible; //显示隐藏弹窗
			},
			info_cel() {
				this.dialogFormVisible = false;
				this.nickname = "";
				this.user = "";
				this.phone = "";
				this.pwd = "";
				this.qrpwd = "";
				this.sta = 0;
				this.is_super = 0;
				this.checkedCities = [];
			},
			info_sub() {
				let {
					nickname,
					user,
					phone,
					pwd,
					qrpwd,
					sta,
					is_super,
					checkedCities
				} = this;
				
				let url = "/adminshop/user/add_user.html";
				let data={
					nickname,
					user,
					phone,
					pwd,
					qrpwd,
					sta,
					is_super,
					checkedCities
				}
				axios.post(url,data).then((res) => {
					let data = res.data;
					if (res.success) {
						if (data.status == 1) {
							this.info_cel();
							this.$message({
								message: data.msg,
								type: 'success'
							});
							this.get_user();
						} else {
							this.$alert(data.msg, {
								title: "提示",
								confirmButtonText: "确定",
								type: "error",
							});
						}
					}
				});
			},
			handleClick(item, sta) {
				let title = "确定要禁用该用户吗？";
				let status = 2;
				if (sta == 1) {
					title = "确定要启用该用户吗？";
					status = 1;
				}
				this.$confirm(title, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.post_status(item.id, status);
				}).catch(() => {

				});
			},
			post_status(id, sta) {
				
				let url = "/adminshop/user/user_status.html";
				let data={
					id,
					sta
				}
				axios.post(url,data).then((res) => {
					let data = res.data;
					if (res.success) {
						if (data.status == 1) {
							this.$message({
								message: data.msg,
								type: 'success'
							});
							let page = this.page;
							this.get_user(page);
						} else {
							this.$alert(data.msg, {
								title: "提示",
								confirmButtonText: "确定",
								type: "error",
							});
						}
					}
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleCheckAllChange(val) {
				let checkedCities = [];
				if (val) {
					this.cities.forEach(function(item) {
						checkedCities.push(item.id);
					});
				}
				this.checkedCities = checkedCities;
			},
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},
		},
	};
</script>

<style scoped>
	@import "user_list.css";
</style>
